<template>
  <div class="ath-button d-flex justify-content-center align-items-center"
       :data-testid="`button-${dataTestid}`"
       :class="$attrs.disabled || loading ? 'disabled' : ''"
       v-bind="$attrs" :disabled="loading">
    <div class="d-flex justify-content-center align-items-center" style="width: max-content">
      <span v-if="iconLeft && !loading" class="d-flex justify-content-center align-items-center">
        <ath-icon :icon="iconLeft" :font-size="iconSize" class='icon-btn ath-mr-4'/>
      </span>
      <div class="d-flex justify-content-center align-items-center">
        <div class='d-flex align-items-center' v-if="!loading">
          <slot>
            <span>ND</span>
          </slot>
        </div>
        <div class="d-flex" v-else>
          <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        </div>
      </div>
      <slot name="right"></slot>
      <span v-if="iconRight && !loading" class="d-flex justify-content-center align-items-center ath-ml-8">
        <ath-icon :icon="iconRight" :font-size="iconSize" class="icon-btn"/>
      </span>
    </div>
    <div class="sup" v-if="sup">{{ sup }}</div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue"
import AthIcon from "@/components/uiElements/icons/AthIcon.vue"

export default defineComponent({
  name: 'AthButton',
  components: {AthIcon},
  props: {
    dataTestid: {type: String, default: '',},
    iconLeft: {type: String, default: ''},
    iconRight: {type: String, default: ''},
    loading: {type: Boolean, default: false},
    fixIcon: {type: Boolean, default: false},
    sup: {type: [String, Number], default: ''},
    iconSize: {type: [Number, String], default: '1.6'},
  },
  inheritAttrs: false,
})
</script>
<style lang="scss" scoped>
@use "@/assets/scss/themes/root" as v;

.btn-create {
  height: 4rem !important;
}

.ath-button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  position: relative;
  border-radius: var(--ath-border-radius-pill);
  height: 4rem;
  cursor: pointer;
  font-family: var(--ath-font-main-medium);
  font-size: var(--ath-font-size--body);
  padding: 0 2rem;
  border: 0.2rem solid transparent;

  .sup {
    position: absolute;
    font-family: var(--ath-font-main-bold);
    font-size: var(--ath-font-size--small);
    color: var(--ath-color-white);
    right: -0.5rem;
    top: -0.5rem;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: var(--ath-color-primary-700);
  }

  .icon-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    display: flex;
    align-items: center;
  }

  &.disabled {
    pointer-events: none;
    color: var(--ath-color-secondary-300) !important;
    background: var(--ath-color-light) !important;
    border: none !important;
  }

  &.disabled.link, &.disabled.link-secondary, &.disabled.link-danger {
    background: var(--ath-color-white) !important;
    color: var(--ath-color-secondary-300) !important;
  }

  &.rounded {
    border-radius: var(--ath-border-radius-pill);
  }

  &.primary {
    color: var(--ath-color-white);
    background: var(--ath-color-primary-700);

    &:hover {
      color: var(--ath-color-primary-700);
      background: var(--ath-color-primary-100);
    }
  }

  &.secondary {
    color: var(--ath-color-primary-700);
    font-family: var(--ath-font-main-medium);
    border-radius: var(--ath-border-radius-pill);
    border: 2px solid var(--ath-color-primary-700);

    &:hover {
      opacity: var(--ath-opacity);
      box-shadow: 0 3px 5px rgba(125, 128, 151, 0.2);
    }
  }

  &.tertiary {
    color: var(--ath-color-secondary-900);
    font-family: var(--ath-font-main-medium);
    border-radius: var(--ath-border-radius-pill);
    border: 1px solid var(--ath-color-secondary-900);

    &.filter {
      border-color: var(--ath-border-color);
    }

    &:hover {
      opacity: var(--ath-opacity);
    }

    &-primary {
      color: var(--ath-color-primary-900);
      border: 1px solid var(--ath-color-primary-900);

      &:hover {
        background: var(--ath-color-primary-100);
        border: 1px solid var(--ath-color-primary-100);
      }
    }
  }

  &.link {
    color: var(--ath-color-primary-700) !important;
    width: fit-content;
    padding: 0;
    height: fit-content;

    &:hover {
      opacity: var(--ath-opacity);
    }
  }

  &.link-secondary {
    color: var(--ath-color-secondary-900) !important;
    width: fit-content;
    padding: 0;

    &:hover {
      color: var(--ath-color-secondary-500) !important;
    }
  }

  &.link-danger {
    color: var(--ath-color-danger) !important;
    width: fit-content;
    padding: 0;

    &:hover {
      opacity: var(--ath-opacity);
    }
  }

  &.success {
    color: var(--ath-color-white);
    background: var(--ath-color-success);

    &:hover {
      background: var(--ath-color-success-light);
      color: var(--ath-color-secondary-900);
    }

    &-outline {
      color: var(--ath-color-success);
      background: transparent;
      border: 0.1rem solid var(--ath-color-success);

      &:hover {
        background: var(--ath-color-success);
        color: var(--ath-color-white);
      }
    }
  }

  &.danger {
    color: var(--ath-color-white);
    background: var(--ath-color-danger);

    &:hover {
      color: var(--ath-color-danger);
      background: var(--ath-color-danger-light);
    }

    &-outline {
      color: var(--ath-color-danger);
      background: transparent;
      border: 0.1rem solid var(--ath-color-danger);

      &:hover {
        background: var(--ath-color-danger);
        color: var(--ath-color-white);
      }
    }
  }

  &.warning {
    color: var(--ath-color-white);
    background: var(--ath-color-warning);

    &:hover {
      color: var(--ath-color-warning);
      background: var(--ath-color-warning-light);
    }

    &-outline {
      color: var(--ath-color-warning);
      background: transparent;
      border: 0.1rem solid var(--ath-color-warning);

      &:hover {
        background: var(--ath-color-warning);
        color: var(--ath-color-white);
      }
    }
  }

  &.primary-light {
    color: var(--ath-color-primary-700);
    background: var(--ath-color-primary-100);

    &:hover {
      opacity: var(--ath-opacity);
    }
  }

  &.filter {
    color: var(--ath-color-secondary-900);
    background: var(--ath-color-light);
    height: auto !important;

    &:hover {
      opacity: var(--ath-opacity);
    }
  }

  &.sm {
    font-size: var(--ath-font-size--caption);
    font-family: var(--ath-font-main-medium);
    height: 3.2rem !important;
    padding: 0 1rem !important;

    .icon-btn {
      font-size: var(--ath-font-size--body) !important;
    }
  }

  &.lg {
    font-size: var(--ath-font-size--title);
    height: 4rem !important;
  }

  &.flex {
    width: 100%;
  }

  &.fit-content {
    width: fit-content;
  }

  &.icon-btn {
    font-size: var(--ath-font-size--body);
  }

  &.border-0 {
    border: none;
  }
}

@media (max-width: v.$mediaMobile) {
  .ath-button {
    font-size: var(--ath-font-size--caption);
    font-family: var(--ath-font-main-medium);
    height: 3.2rem;

    .icon-btn {
      font-size: var(--ath-font-size--body);
    }
  }
}
</style>
