import Alert from "../../../helpers/AlertHelper";
import SecurityService from "../oidc";
import subdomain from "@/helpers/subdomain";
import Event from "@/helpers/event";

const SingletonInstanceSymbol = Symbol();
const InstanceSymbol = Symbol();
const signalR = require('@microsoft/signalr');

class SignalR {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct SignalR Instance');
    }
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new SignalR(SingletonInstanceSymbol);
    }
    return this[InstanceSymbol];
  }
  getLogLevel(){
    if (`${process.env.VUE_APP_VERSION}` == "development") {
      return signalR.LogLevel.Information;
    }
      //In production not need to log, only for debbuging in critical cenerarios
    return signalR.LogLevel.Error
  }

  async setConnection(url) {
    const user = await SecurityService.Mgr().getUser();
    const log = this.getLogLevel()

    return new Promise((resolve, reject) => {
      try {
        this.connection = new signalR.HubConnectionBuilder()
          .withUrl(url, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            accessTokenFactory: () => user.access_token
          })
          .configureLogging(log)
          .withAutomaticReconnect()
          .build();
        resolve(this.connection);
      } catch (e) {
        console.log("reject", e);
        reject(e);
      }
    });
  }

  async start() {
    try {
      await this.connection.start();

      this.connection.on("MessageUser", (message) => {
        Alert.notify('error', message, '', '', false, 1000000000000000);
      });

      this.connection.on('closeinvoice', (message) => {
        Event.emit('signalRCloseInvoice', message)
      });
    } catch (err) {
      setTimeout(() => this.start(), 5000);
      console.log(err);
    }
  }

  async initConnections() {
    await this.setConnection(`${ process.env.VUE_APP_PROTOCOL }${ subdomain }${ process.env.VUE_APP_PROVIDER_GATEWAY }/signalr-hubs/message-user`)

    const protocolSignIR = process.env.VUE_APP_PROTOCOL === 'https://' ? 'wss' : 'ws'

    await this.setConnection(`${protocolSignIR}:${ subdomain }${ process.env.VUE_APP_PROVIDER_GATEWAY }/bulk-invoice-generation/hub`)
    await this.start();
  }
}

export default SignalR.instance;
