import Alert from '@/helpers/AlertHelper';
import provider from '@/provider';
import i18n from '@/i18n';
import Format from '@/helpers/format'
import { useRoute } from "vue-router";
import Date from "@/helpers/date";
import invoiceService from "@/modules/invoice/services/invoiceService";
import permission from "@/app/permission";
import {useUserState} from "@/modules/users/store";
import IntegrationOidc from "@/app/integrations/oidc";

const sanitizeHtml = require('sanitize-html');

const Helper = () => {
  return {
    self: this,

    async loadCep(cepUnmasked) {
      if (cepUnmasked.length >= 8) {
        return provider.public.get(`https://viacep.com.br/ws/${ cepUnmasked }/json`).then((response) => {
          if (response.data.erro) {
            Alert.notify('error', i18n.global.t('ErrorCepNotFound'));
            return null;
          }
          return response.data;
        }).catch(() => {
          Alert.notify('error', i18n.global.t('ErrorCepNotFound'));
        });
      }
      return null;
    },
    isValidCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj.length !== 14)
        return false;

      if (/^(\d)\1+$/.test(cnpj))
        return false;

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
        if (pos < 2)
          pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== parseInt(digitos.charAt(0), 10))
        return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i), 10) * pos--;
        if (pos < 2)
          pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      return resultado === parseInt(digitos.charAt(1), 10);
    },
    isValidCPF(cpf) {
      cpf = cpf.replace(/[^\d]+/g, '');

      if (cpf.length !== 11 || !/^\d{11}$/.test(cpf)) return false

      const digits = cpf.split('').map(Number);

      if (digits.every(digit => digit === digits[0])) return false;

      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += digits[i] * (10 - i);
      }
      let digit1 = 11 - (sum % 11);
      if (digit1 > 9) digit1 = 0;
      if (digits[9] !== digit1) return false

      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += digits[i] * (11 - i);
      }
      let digit2 = 11 - (sum % 11);
      if (digit2 > 9) digit2 = 0;
      return digits[10] === digit2;
    },
    cloneObject(obj) {
      if (!obj) return undefined
      return JSON.parse(JSON.stringify(obj));
    },
    getDownloadFileInWebSocket(responseId = '') {
      let url = ''
      let numberOfRequests = 0

      const timer = setInterval(async () => {
        const result = await invoiceService.checkDonwloadIsFinished(responseId)
        url = result.url
        numberOfRequests++

        if (url != null || numberOfRequests === 40) {
          clearInterval(timer)

          if (numberOfRequests === 40) {
            Alert.notify('error', i18n.global.t('DownloadInvoiceError'));
          } else {
            const textLink = document.createTextNode(i18n.global.t('ClickHereToDownload'));
            const linkDownload = document.createElement('a');
            linkDownload.appendChild(textLink);
            linkDownload.href = url;

            const matches = url.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g);
            if (matches.length !== 2) {
              return;
            }

            const id = matches[1];
            linkDownload.id = id;

            Alert.notify(
              'success',
              i18n.global.t('FinishedDownload'),
              '',
              [linkDownload],
              false,
              1000000000000000
            );

            document.addEventListener('click', function (e) {
              if (e?.target?.id == id) {
                e?.target?.parentNode.parentNode.parentNode.remove();
              }
            });
          }
          const element = document.getElementById(`${ responseId }`);
          if (element) {
            element.remove();
          }
        }
      }, 2000);
    },
    isValidUrlProtocol(url, protocol = 'https:') {
      const regex = new RegExp(`^(${protocol}\\/\\/)(www\\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&\\/\\=]*)$`);
      return regex.test(url);
    },
    getPercent(percent) {
      if (!percent || percent <= 0 || isNaN(percent)) return '0%'
      return `${ (percent * 100).toFixed(2) }%`
    },
    getPercentUnmasked(percent) {
      if (!percent) return 0;

      if (typeof percent === "number") return +(percent * 100).toFixed(2);

      if (typeof percent === "string") {
        percent = percent.replace(',', '.');
        if (!isNaN(percent)) return +(parseFloat(percent) * 100).toFixed(2);
      }

      return 0;
    },
    sentPercent(percent) {
      if (!percent) return '0';

      if (typeof percent === "number") percent = percent.toString();
      percent = percent.replace(",", ".").replace("%", "").trim();

      if (!percent || isNaN(percent) || parseFloat(percent) <= 0) return '0';
      return (parseFloat(percent) / 100).toFixed(4);
    },
    downloadFile(response, fileName) {
      const fileType = response.headers['content-type'];
      const file = new Blob([response.data], { type: fileType });
      const fileUrl = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = fileUrl;
      a.target = '_blank';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getAllInitialFiltersUrl(fields = [], filterByDefault = 'DueDate', setDayNow = false) {
      const initialFilters = []

      fields.forEach((fieldUrl) => {
        if (fieldUrl === 'DateFilter') {
          const filterBy = this.getFiltersUrl('filterBy', filterByDefault, true)
          const dateFilterStart = this.getFiltersUrl('DateFilterStart', '', true)
          const dateFilterEnd = this.getFiltersUrl('DateFilterEnd', '', true)

          let start, end;
          if (setDayNow) {
            start = dateFilterStart ? Date.transformToDateTime(dateFilterStart) : Date.dateNow();
            end = dateFilterEnd ? Date.transformToDateTime(dateFilterEnd) : Date.dateNow();
          } else {
            start = dateFilterStart ? Date.transformToDateTime(dateFilterStart) : Date.getStartOfMonth()
            end = dateFilterEnd ? Date.transformToDateTime(dateFilterEnd) : Date.getEndOfMonth()
          }

          const newFilter = {
            field: 'DateFilter',
            type: 'like',
            value: { start, end },
          }

          if (filterByDefault) newFilter.value.filterBy = filterBy

          initialFilters.push(newFilter)
        } else {
          const fieldValue = this.getFiltersUrl(fieldUrl);
          if (fieldValue.value) initialFilters.push(fieldValue)
        }
      })

      return initialFilters
    },
    getFiltersUrl(field = '', defaultValue = '', onlyValue = false) {
      const route = useRoute()
      const filterRouteQuery = route?.query

      if (filterRouteQuery && field in filterRouteQuery) {
        if (onlyValue) return filterRouteQuery[field]
        return {
          field: field,
          type: 'like',
          value: filterRouteQuery[field],
        }
      }

      return defaultValue
    },
    getFilterUrlSorter() {
      const route = useRoute()
      const filterRouteQuery = route?.query

      if (!(/Sorting/.test(filterRouteQuery))) {
        return '&Sorting=dueDate&SortType=Asc'
      }

      return ''
    },
    verifySensitiveData(string, ignoreVisibility = false) {
      const visibilitySensitiveData = this.getIsVisibilitySensitiveData();
      if (!ignoreVisibility && !visibilitySensitiveData || !string || string === '---') {
        return string;
      }

      const isCpfCnpj = () => string.match(/\d{11}/) || (string.includes('.') && string.includes('-'))
      const isDate = () => string.match(/\d{2}\/\d{2}\/\d{4}/)
      const isEmail = () => string.match(/^[\w*.]+@([\w]+\.)+[\w]{2,4}/)

      if (isCpfCnpj()) {
        string = `${ Format.onlyNumberOfString(string, false) }`
        return string.replace(/(\d{3})\d{6}(\d{2})/, '$1.***.***-$2')
      }

      if (isDate()) {
        if (string.match(/-*-/)) {
          const dateTime = new Date(string)
          string = dateTime.toLocaleDateString()
        }
        return string.replace(/(\d{2}\/\d{2}\/)(\d{4})/, '$1****')
      }

      if (isEmail()) {
        const regex = /([\w|.]+)@([\w|.]+)/
        const split = regex.exec(string)
        return `${ split[1].charAt(0) }*****@${ split[2] }`
      }

      const splitStr = string.split(' ');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = `${ splitStr[i].charAt(0) }*****`;
      }

      return splitStr.join(' ')
    },
    getIsVisibilitySensitiveData() {
      return JSON.parse(sessionStorage.getItem('isVisibleSensitiveData'))
    },
    segmentAnalytics(analytics, local, element, type = 'page') {
      if (process.env.VUE_APP_EDUCBANK_ENVIRONMENT?.toLowerCase() === 'production') {
        if (type === 'page')
          analytics?.page(local, element)
        else if (type === 'track')
          analytics?.track(local, element)
        else
          analytics?.identify(local, element)
      }
    },
    sanitizeHTML(html, options) {
      return sanitizeHtml(html, options)
    },
    scrollToTop(id) {
      const scrollTopElement = document.querySelector(id);

      scrollTopElement?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    async getTenantIdSelectedOrLogged() {
      if (permission.isHost())
        return useUserState().tenantSelected.id
      else {
        const tokenUser = await IntegrationOidc.Mgr().getUser()
        return `${tokenUser?.profile.tenantid}`
      }
    },
    createHrefRoute(name, params, event, router, query) {
      const routeData = router?.resolve({name, params, query}).href;

      if (!event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        router?.push(routeData);
      } else {
        window.open(routeData, '_blank');
      }
    },
    phoneValidate(value) {
      const valueTrimmed = value.replace(/\+55\s?|\s|-|\(|\)/g, '').trim();
      if (!valueTrimmed || valueTrimmed.length < 10 || valueTrimmed.length > 11 || /[^\d]/.test(valueTrimmed)) {
        return false
      }
      return true
    }
  };
};

export default Helper();
