import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/enrollments-old',
    name: 'enrollments',
    component: () => import(/* webpackChunkName: "enrollments" */ '@/modules/enrollment/views/EnrollmentManagement.vue'),
  },
  {
    path: '/enrollments-old/:id',
    name: 'enrollmentsDetails',
    component: () => import(/* webpackChunkName: "enrollments-details" */ '@/modules/enrollment/views/EnrollmentDetails.vue'),
  },
  {
    path: '/enrollments',
    component: () => import(/* webpackChunkName: "enrollments" */ '@/modules/student-area/views/StudentAreaList.vue'),
  },
  {
    path: '/enrollments/:id',
    component: () => import(/* webpackChunkName: "enrollments-details" */ '@/modules/student-area/views/StudentAreaDetails.vue'),
  },
]

export default routes
